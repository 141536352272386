import React from "react"
import { PageProps, Link } from "gatsby"
import Helmet from "react-helmet"

import "../sass/main.scss"
import { Layout } from "../components"
import { Row, Col, Container } from "react-bootstrap"
const NotFound = () => (
  <Layout step={1}>
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={"Satalia | page not found"}
      titleTemplate={`%s`}
    >
      <meta name="robots" content="noindex, follow" />
      <meta name="twitter:title" content="Satalia | page not found" />
      <meta name="og:title" content="Satalia | page not found" />
    </Helmet>
    <Container>
      <Row className="justify-content-center pb-185">
        <Col xs="3" sm="4" md="3" lg="4">
          <div className="st-block st-block--head">
            <h1>Error Code: 404</h1>
          </div>
        </Col>
        <Col xs="3" sm="4" md="3" lg="4">
          <div className="st-block st-block--head st-block--head-xs-0">
            <h3>Page not found</h3>
          </div>
        </Col>

        <Col xs="3" sm="8" md="4" lg="4" className="z-index-1001">
          <div className="st-block st-block--head st-block--head-xs-0">
            <p className="text-inherit">
              Sorry, we can’t find that page. It might be an old link or maybe
              it was removed.
            </p>
            <Link to="/" className="learn-more" title="Satalia">
              Back to homepage
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFound
